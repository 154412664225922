.logo-figure {
  margin: 0;
  padding-bottom: 2.5rem;
  text-align: center;
  position: relative;
  img {
    display: block;
    margin: 0 auto;
  }
  .subtitle {
    display: inline-block;
    margin-top: 0.4375rem;
    font-size: 0.9375rem;
    text-align: center;
    white-space: nowrap;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}

/*------------------------------------*\
    $BASE
\*------------------------------------*/

/* Tipos */

@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,&display=swap');
@include font-face-old('alantra-account-icons', 'AlAcIcons');

/* Global */

html {
  font-size: $document-font-size;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100%;
  min-height: 360px;
  box-sizing: border-box;
  font-size: $document-font-size;
  background: $baseBg;
  color: $text;
  font-family: $font;
  line-height: $base-lineHeight;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    box-sizing: border-box;
  }

  ::selection {
    background: $alantra90;
    color: $white;
  }
  ::-moz-selection {
    background: $alantra90;
    color: $white;
  }

  /* Content */

  iframe {
    max-width: 100%;
  }

  img {
    vertical-align: middle;
    max-width: 100%;
    height: auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  ul,
  li,
  ol,
  dl,
  dd {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  p {
    line-height: 1.1;
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
    &:hover,
    &:focus {
      outline: none;
    }
  }

  p a {
    color: $linkText;
  }

  form {
    margin: 0;
  }

  button,
  input,
  textarea,
  select {
    -webkit-appearance: none;
    &:active,
    &:focus {
      outline: none;
    }
  }

  button[disabled],
  input[disabled] {
    cursor: default;
  }

  textarea {
    overflow: auto;
    vertical-align: top;
  }

  button,
  input,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
  }

  input[type='checkbox'],
  input[type='radio'] {
    width: auto;
  }
}

.wrapper {
  height: 100%;
  min-height: 360px;
  position: relative;
}

/* Helpers */

.reader-only {
  @include hide-content;
}

/*------------------------------------*\
    $VARIABLES
\*------------------------------------*/

// -------------------------------------
//   Colors
// -------------------------------------

// Greyscale
$white: #fff;
$cool_grey_100: #2e3338;
$cool_grey_80: #515961;
$cool_grey_60: #8995a1;
$cool_grey_40: #dce0e3;
$cool_grey_20: #eeeff1;
$cool_grey_10: #f3f3f4;
$black: #000;

// Alternative greyscale
$grey_60: #91959a;
$grey_40: #dedfe3;
$grey_10: #fbfbfb;

// ------- Functional colors ------- //

$alantra100: #2a3425;
$alantra90: #274913;
$alantra80: #41774f;
$alantra60: #668f71;
$alantra40: #a4bcab;
$alantra30: #dbe4dd;
$alantra20: #edf2ee;

$baseBg: $white;

$text: $cool_grey_80;
$text_light: $cool_grey_60;
$text_dark: $cool_grey_100;
$linkText: $alantra80;

$success: #78e08f;
$highlight_light: #f8e4c3;
$highlight: #e8a638;
$highlight_alternative: #eaae4b;
$highlight_active: #ffba47;
$warning: #d7a026;
$error: #ff5e57;
$error_light: #ff7e7e;
$info: #648cbe;
$info_light: #80afe5;

// -------------------------------------
//   Fonts
// -------------------------------------

$font: 'Source Sans Pro', Helvetica, Arial, sans-serif;

$base-lineHeight: normal;

$regular: 400;
$semibold: 600;
$black: 900;

$font-base: 16;
$document-font-size: ($font-base / 16) * 100%;

// Extended font sizes

// $font_size_zeta         : 14;     // h6
// $font_size_epsilon      : 16;     // h5
// $font_size_delta        : 18;     // h4
// $font_size_gamma        : 20;     // h3
// $font_size_beta         : 24;     // h2
// $font_size_alpha        : 28;     // h1

// $font_size_kilo         : 30;     // extra-large
// $font_size_mega         : 38;     // extra-Xlarge
// $font_size_giga         : 46;     // jumbotronik

// -------------------------------------
//   Loading buttons animation
// -------------------------------------

// Values for animations:
//   ellip =  . .. ...
//   dots = ⠋ ⠙ ⠹ ⠸ ⠼ ⠴ ⠦ ⠧ ⠇ ⠏
//   dots2 = ⠋ ⠙ ⠚ ⠞ ⠖ ⠦ ⠴ ⠲ ⠳
//   bar = ▏ ▎ ▍ ▌ ▋ ▊ ▉
//   circle = ◴ ◷ ◶ ◵
//   beam =  =     ==    ===   ====   ===    ==     =
//   bullet =  ●        ●        ●        ●        ●      ●      ●      ●      ●      ●
//   rhomb = ◇◇◇ ◈◇◇ ◇◈◇ ◇◇◈
//   toggle = ⊶ ⊷
//   time = 🕐 🕑 🕒 🕓 🕔 🕕 🕖 🕗 🕘 🕙 🕚 🕛
//   yo-ke-se-tio =  ¯\_(ツ)_/¯   __(ツ)__   ¯\_(ツ)_/¯   __(ツ)__
//   custom = Does'nt load any animation, you must to add your custom one

$loadingAnimation: dots;

// -------------------------------------
//   Buttons dimensions
// -------------------------------------

$button-height: 44px;
$button-height-small: 33px;
$button-width: 160px;

// -------------------------------------
//   Z-Index levels
// -------------------------------------

$z-index-toast: 10000;
$z-index-modal: 9000;
$z-index-overlay: 8000;
$z-index-dropdown: 7000;
$z-index-dialog: 6000;
$z-index-popup: 5000;
$z-index-default: 1;
$z-index-deepdive: -99999;

// -------------------------------------
//   Layout
// -------------------------------------

$l-width: 98%;
$l-maxWidth: 1200px;

$base-whitespace: 1em;

// -------------------------------------
//   Grid
// -------------------------------------

// inline-block, float o none

$grid: inline-block;

// -------------------------------------
//   Breakpoints
// -------------------------------------

// palm: for handheld devices
// lap: for tablets and netbooks and the like
// portable: for both of the above
// desk: for anything big enough to be deemed stationary

$lap-start: 700;
$desk-start: 1024;
$desk-wide-start: 1200;

$palm-end: $lap-start - 0.01;
$lap-end: $desk-start - 0.01;

%icon,
.icon {
  display: inline-block;
  vertical-align: middle;
  font-family: 'alantra-account-icons';
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  speak: none;
  overflow: hidden;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon svg {
  display: block;
  height: 100%;
  max-width: 100%;
}

.no-svg .icon {
  svg {
    display: none !important;
  }
  &:before {
    display: inline-block;
  }
}

//Usage:
//<span className="icon icon-add-new" aria-hidden=true></span>

.icon-key:before {
  content: '\e900';
}
.icon-new_user:before {
  content: '\e901';
}

%button {
  -webkit-appearance: none;
  display: inline-block;
  vertical-align: middle;
  @include fontSize(15);
  letter-spacing: 0.3px;
  font-family: $font;
  font-weight: $regular;
  text-align: center;
  border: none;
  line-height: 1;
  cursor: pointer;
  margin: 0;
}

// BUTTON

.button {
  @extend %button;
  color: $linkText;
  font-weight: $semibold;
  @include remSize(padding, 0 20);
  border-radius: 25px;
  white-space: nowrap;
  min-width: 100px;
  background-color: $alantra20;
  span {
    vertical-align: middle;
  }
  .icon {
    @include fontSize(16);
    margin-right: 0.4375rem;
  }
  &.is-full-width {
    width: 100%;
  }
  &:hover,
  &:focus {
    box-shadow: 0 2px 4px 0 rgba($cool_grey_60, 0.5);
  }
  &:active {
    background-color: $alantra30;
  }
  &[disabled],
  &.is-disabled {
    opacity: 0.5;
    &:hover,
    &:focus {
      box-shadow: none;
    }
    &:active {
      background-color: $alantra20;
    }
  }
}

// LINK BUTTON

a {
  &.button {
    &.is-disabled {
      cursor: default;
      pointer-events: none;
    }
  }
}

.button-inner-wrapper {
  line-height: $button-height-small;
}

.account-selector {
  padding: 4.375rem 0;
  width: 14.6875rem;
  margin: 0 auto;
  text-align: center;
  .button {
    & + .button {
      margin-top: 0.875rem;
    }
  }
  @include media-query(lap-and-up) {
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
